import { GoogleAuthProvider, auth, createUserWithEmailAndPassword, db, signInWithEmailAndPassword, signInWithPopup, signOut } from "../firebase";
import { collection, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from "firebase/firestore";

// get user data
export const getUserDocument = async (uid) => {
    try {
        const userDoc = await getDoc(doc(db, "users", uid));
        return userDoc.exists() ? userDoc.data() : null;
    } catch (error) {
        console.error("Error fetching user", error);
        return null;
    }
};

// Create user
export const createUserDocument = async (user) => {
    try {
        await setDoc(doc(db, "users", user.uid), {
            uid: auth.currentUser.uid,
            displayName: user.displayName || 'Aucun nom',
            email: user.email,
            photoURL: user.photoURL ? user.photoURL : 'https://flaviendev.b-cdn.net/default.jpg',
            status: "new",
            type: "shop",
            created: new Date(),
            money: {
                payment: 0
            }
        });
    } catch (error) {
        console.error("Error creating user document", error);
    }
};

// assign picture to user
export const assignPictureToUser = async (uid, url) => {
    try {
        const userRef = doc(db, "users", uid);
        await updateDoc(userRef, {
            photoURL: url
        });
        console.log('Picture added')
        return { success: true };
    } catch (error) {
        console.log('Picture not added', error)
        return { success: false, error: error };
    }
};

// update user data
export const updateUserName = async (uid, name) => {
    try {
        const userRef = doc(db, "users", uid);
        await updateDoc(userRef, {
            displayName: name
        });
        return { success: true };
    } catch (error) {
        return { success: false, error: error };
    }
};

// logout user
export const logOutUser = () => {
    signOut(auth).then(() => {
        // Navigate('/');
        // console.log('deconnexion')
    }).catch((error) => {
        console.log(error)
    });
}

// user login by email
export const userLoginByEmail = async (email, password) => {
    try {
        await signInWithEmailAndPassword(auth, email, password);
        return { success: true };
    } catch (err) {
        console.log(err);
        return { success: false, error: err };
    }
}

// user register by email
export const userRegisterByEmail = async (email, password) => {
    try {
        await createUserWithEmailAndPassword(auth, email, password);
        return { success: true };
    } catch (err) {
        console.log(err);
        return { success: false, error: err };
    }
}

// user login with google
export const userLoginByGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
        await signInWithPopup(auth, provider)
            .then((result) => {
                GoogleAuthProvider.credentialFromResult(result);
            }).catch((error) => {
                console.log(error);
            });
    } catch (err) {
        console.log(err);
    }
}