import { Navigate, useRoutes } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import DashboardPage from './pages/DashboardPage';
import Login from './pages/Login';
import { useAuth } from './contexts/AuthProvider';

export default function Router() {
    const { currentUser } = useAuth();

    const routes = useRoutes([
        {
            path: '/dashboard',
            element: currentUser ? <Dashboard /> : <Navigate to="/login" replace />,
            children: [
                { element: <Navigate to="/dashboard/app" />, index: true },
                { path: 'app', element: <DashboardPage /> },
            ],
        },
        {
            path: '/login',
            element: currentUser ? <Navigate to="/dashboard" replace /> : <Login />,
        },
        {
            path: '*',
            element: <Navigate to="/login" replace />,
        },
    ]);

    return routes;
}