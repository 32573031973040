import React, { useState } from 'react'
import { userLoginByGoogle, userLoginByEmail, userRegisterByEmail } from '../services/firebase.service';

import logo from '../assets/logo.png';
import loading from '../assets/loading.gif';
import googleIcon from '../assets/google.png';
import '../styles/login.css';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Login() {
    // login
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    // register
    const [emailRegister, setEmailRegister] = useState('');
    const [passwordRegister, setPasswordRegister] = useState('');
    const [passwordConfirmRegister, setPasswordConfirmRegister] = useState('');

    const [isRegisterStep, setIsRegisterStep] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const handleLoginSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const responseLogin = await userLoginByEmail(email, password);
        if (responseLogin.success) {
            toast.success('Connexion, vous allez être redirigé.', {
                position: "top-center"
            })
        } else {
            toast.error(responseLogin.error.message, {
                position: "top-center"
            })
        }
        setIsLoading(false);
    }

    const handleRegisterSubmit = async (e) => {
        e.preventDefault();
        if (passwordRegister !== passwordConfirmRegister) {
            toast.error("Les mots de passe ne correspondent pas !", {
                position: "top-center"
            })
            return;
        }
        setIsLoading(true);
        const response = await userRegisterByEmail(emailRegister, passwordRegister);
        if (response.success) {
            toast.success('Inscription, vous allez être redirigé.', {
                position: "top-center"
            })
        } else {
            toast.error(response.error.message, {
                position: "top-center"
            })
        }
        setIsLoading(false);
    }

    return (
        <div className='login'>
            {!isRegisterStep ? (
                <div className='login-container'>
                    <h1>Connexion</h1>
                    <form className='login-form' onSubmit={handleLoginSubmit}>
                        <div className='login-field-container'>
                            <div className="input-container">
                                <input type='text' value={email} onChange={(val) => setEmail(val.target.value)} required />
                                <label className={email && 'filled'} >
                                    Adresse mail
                                </label>
                            </div>
                            <div className="input-container">
                                <input type='password' value={password} onChange={(val) => setPassword(val.target.value)} required />
                                <label className={password && 'filled'} >
                                    Mot de passe
                                </label>
                            </div>
                        </div>
                        {isLoading ? (
                            <button className='login-submit' type="submit" disabled><img src={loading} alt='Chargement' /></button>
                        ) : (
                            <button className='login-submit' type="submit">Connexion</button>
                        )}
                        <button className='login-google' type='button' onClick={userLoginByGoogle}><img src={googleIcon} alt='Google' /></button>
                        {/* <div className='login-buttons-container'>
                            <button className='login-submit' type="submit">Connexion</button>
                            <button className='login-google' type='button' onClick={userLoginByGoogle}><img src={googleIcon} alt='Google' /></button>
                        </div> */}
                    </form>
                    {/* <div className='login-footer'>
                        <button onClick={() => setIsRegisterStep(!isRegisterStep)} className='login-link'>Pas encore inscrit ?</button>
                    </div> */}
                </div>
            ) : (
                <div className='login-container'>
                    <h1>Inscription</h1>
                    <form className='login-form' onSubmit={handleRegisterSubmit}>
                        <div className="input-container">
                            <input type='text' style={{width: '100%'}} value={emailRegister} onChange={(val) => setEmailRegister(val.target.value)} required />
                            <label className={emailRegister && 'filled'} >
                                Adresse mail
                            </label>
                        </div>
                        <div className='login-field-container'>
                            <div className="input-container">
                                <input type='password' value={passwordRegister} onChange={(val) => setPasswordRegister(val.target.value)} required />
                                <label className={passwordRegister && 'filled'} >
                                    Mot de passe
                                </label>
                            </div>
                            <div className="input-container">
                                <input type='password' value={passwordConfirmRegister} onChange={(val) => setPasswordConfirmRegister(val.target.value)} required />
                                <label className={passwordConfirmRegister && 'filled'} >
                                    Confirmation
                                </label>
                            </div>
                        </div>
                        {isLoading ? (
                            <button className='login-submit' type="submit" disabled><img src={loading} alt='Chargement' /></button>
                        ) : (
                            <button className='login-submit' type="submit">Inscription</button>
                        )}
                        <button className='login-google' type='button' onClick={userLoginByGoogle}><img src={googleIcon} alt='Google' /></button>

                    </form>
                    <div className='login-footer'>
                        <button onClick={() => setIsRegisterStep(!isRegisterStep)} className='login-link'>J'ai déjà un compte ?</button>
                    </div>
                </div>
            )}
            <ToastContainer />
        </div>
    )
}
