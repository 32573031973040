import React from 'react'
import { logOutUser } from '../services/firebase.service'

export default function DashboardPage() {
    return (
        <div>
            Dashboard
            <button onClick={() => logOutUser()}>Deco</button>

        </div>
    )
}
