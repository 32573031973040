import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, signInWithPopup, signOut, createUserWithEmailAndPassword } from "firebase/auth";
import { GoogleAuthProvider, onAuthStateChanged } from "firebase/auth";

import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyACE-1AeacYNuBfYILFcD7ToHy-bnzke5c",
    authDomain: "kdoscan-e30ca.firebaseapp.com",
    projectId: "kdoscan-e30ca",
    storageBucket: "kdoscan-e30ca.appspot.com",
    messagingSenderId: "662847353445",
    appId: "1:662847353445:web:9c5406a60d07885aa5cc8c",
    measurementId: "G-YBQ7H84YDJ"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export const imageDb = getStorage(app);

export { auth, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, signOut, createUserWithEmailAndPassword, onAuthStateChanged, db };